import React from "react";
import { Layout, SEO, DefaultHeader, Footer } from "components";
import { graphql } from "gatsby";
import styled from "styled-components";
import { P, H3 } from "../mixins";
import { ChecklistIcon, ReportIcon, ComplianceIcon, LightBlueCircle } from "assets/svgs";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import VideoPlaceholderImage from "assets/images/video-placeholder.jpg";
import ClassTestingImage from "../assets/images/class-testing.jpg";
import ClassPlusImage from "../assets/images/class-plus.jpg";
import { FadeInUp } from "components";

export const query = graphql`
  query ClassPageQuery {
    banner: file(relativePath: { eq: "assets/images/class-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brochure :  sanityDownload(title: {eq: "LHS Services C.L.A.S.S."}) {
      title
      pdf {
        asset {
          url
        }
      }
    }
  }
`;

const ClassPage = (props) => {
  const { data, errors } = props;
  const { banner, brochure } = data;

  return (
    <Layout>
      <SEO
        title="Class"
        description="The future of full compliance. We are here to support you 24/7, 365 days a year with our award-winning C.L.A.S.S. Inspection System."
      />
      <DefaultHeader
        title="C.L.A.S.S."
        description="THE FUTURE OF FULL COMPLIANCE. We are here to support you 24/7, 365 days a year with our award-winning C.L.A.S.S. Inspection System. "
        imageFluid={banner}
      />
      <Container>
        <About>
          <Content>
            <FadeInUp>
              <H3>About C.L.A.S.S.</H3>
              <P>
                The LHS inspection program C.L.A.S.S. is a bespoke software technology that is used
                by our customers to ensure that materials handling, and safety equipment is
                certified in accordance with National and European legislation.
              </P>
               
              <P>
                LHS is the first company in the statutory certification field within Ireland to
                develop a state of the art, bespoke, online safety management programme. The
                C.L.A.S.S. system allows you, the client, to view a comprehensive suite of reports
                surrounding your own statutory inspection history, plant failures, repairs and
                statistical analysis.
              </P>
              <P>
                The C.L.A.S.S. software was the first programme in Ireland to prove compliance in a
                court of Law, where it defended and won the case!
              </P>
               
              <P>
                The benefits of our C.L.A.S.S. data analytics, ensures you can more readily prevent
                costly incidents and accidents as well as identify efficiencies and link them with
                real cost saving opportunities.
              </P>
            </FadeInUp>
            <FadeInUp>
              <Button to="/about/#contactform" stripHash>
                Request A Demo Today
              </Button>
            </FadeInUp>
          </Content>
          <Visual>
            <LightBlueCircle />
            <FadeInUp>
              <img src={VideoPlaceholderImage} alt="video placeholder" />
            </FadeInUp>
          </Visual>
        </About>
        <WhatClassCanDo>
          <Content>
            <FadeInUp>
              <H3>What C.L.A.S.S. Can Do</H3>
              <P>
                We believe safety can be a profit opportunity for you. C.L.A.S.S. uses its data to
                provide you with the insights needed to make effective cost and safety decisions.
                Operating 24/7, Class allows you to be able to continually monitor the safety of
                your equipment resulting in Visualised Data Reports, Decision Tree Functionality and
                a LHS patented Overall Equipment Efficiency (OEE) rating.
              </P>
              <P>To find out more about what Class can do please download our brochure.</P>
            </FadeInUp>
            <FadeInUp>
              <LinkButton href={brochure.pdf.asset.url} target="blank">
                Download Brochure
              </LinkButton>
            </FadeInUp>
          </Content>
          <Visual>
            <FadeInUp>
              <img src={ClassPlusImage} alt="video placeholder" />
            </FadeInUp>
          </Visual>
        </WhatClassCanDo>
        <About>
          <Visual>
            <FadeInUp>
            <img src={ClassTestingImage} alt="video placeholder" />
            </FadeInUp>
          </Visual>
          <ClassTesting>
            <SvgContainer>
              <ChecklistIcon />
            </SvgContainer>
            <p>C.L.A.S.S. meets all the Statutory Regulations and requirements for reporting.</p>
            <SvgContainer>
              <ReportIcon />
            </SvgContainer>
            <p>
              Extensive information will be provided on any inspection you run. Separate, easy to
              read reports that are easy to understand and act on, are available at any time.
            </p>
            <SvgContainer>
              <ComplianceIcon />
            </SvgContainer>
            <p>
              The C.L.A.S.S. software was the first programme in Ireland to prove compliance in a
              court of Law, where it defended and won the case!
            </p>
          </ClassTesting>
        </About>
      </Container>
      <Footer />
    </Layout>
  );
};

const LinkButton = styled.a`
  background: #f94c00;
  color: white;
  width: auto;
  height: 3rem;
  font-size: 0.875rem;
  padding: 0.5rem 2rem;
  border-radius: 6px;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  transition: 0.5s;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
`;

const Button = styled(AnchorLink)`
  background: #f94c00;
  color: white;
  width: auto;
  height: 3rem;
  font-size: 0.875rem;
  padding: 0.5rem 2rem;
  border-radius: 6px;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  transition: 0.5s;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
`;

const Content = styled.div`
  grid-area: content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  h3 {
    color: #223f92;
    font-size: 1.25rem;
    line-height: 1.5em;
    margin-top: 0;
  }
  p {
    width: 100%;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5em;
  }
  @media (min-width: 768px) {
    padding: 5rem;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
  }
  @media (min-width: 1150px) {
    padding: 0;
    align-items: flex-start;
    max-width: 100%;
    margin: 0;
  }
`;

const Visual = styled.div`
  width: 100%;
  height: auto;
  grid-area: visual;
  position: relative;
  img {
    max-width: 100%;
    position: relative;
    z-index: 2;
  }
  svg {
    position: absolute;
    left: -50%;
    top: -50%;
    width: 100%;
    height: auto;
    z-index: 1;
  }
  @media (min-width: 768px) {
    text-align: center;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    svg {
      left: -80%;
      top: -50%;
      width: 100%;
    }
  }
  @media (min-width: 1150px) {
    svg {
      left: -80%;
      top: -30%;
      width: 125%;
    }
  }
`;

const Article = styled.article`
  grid-column: 1/7;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 3rem;
  margin-bottom: 5rem;

  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr;
    grid-column: 2/12;
  }
`;

const SvgContainer = styled.div`
  width: 5rem;
  height: 5rem;
  padding: 2rem;
  border-radius: 0.425rem;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  background: #eaf6ff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    height: 2rem;
    min-width: 2rem;
    max-width: 80%;
    max-height: 80%;
  }
  @media (min-width: 1150px) {
    width: 7.5rem;
    height: 7.5rem;
    svg {
      position: absolute;
      height: 3.5rem;
      min-width: 3.5rem;
      max-width: 80%;
      max-height: 80%;
    }
  }
`;

const ClassTesting = styled(Content)`
  width: 100%;
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-gap: 2rem;
  padding: 0;
  align-items: center;
  p {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25em;
    color: #232528;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 7.5rem 1fr;
  }
`;

const About = styled(Article)`
  grid-template-areas: "visual" "content";
  @media (min-width: 1150px) {
    grid-template-areas: "visual content";
  }
`;

const WhatClassCanDo = styled(Article)`
  grid-template-areas: "visual" "content";
  @media (min-width: 1150px) {
    grid-template-areas: "content visual";
  }
`;

const Container = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 5rem auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default ClassPage;
